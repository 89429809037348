import {createStore} from 'vuex'
import cookie from '@/utils/cookie'

export default createStore({
    state: {
        token: cookie.get('token') || '',
        admin: JSON.parse(cookie.get('admin') || '{}'),
    },
    getters: {
        token: state => {
            return state.token
        },
        admin: state => {
            return state.admin
        }
    },
    mutations: {
        setToken: (state, value: string) => {
            state.token = value
        },
        setAdmin: (state, value: object) => {
            state.admin = value
        }
    },
    actions: {
        setToken: (state, value: string) => {
            state.commit('setToken', value)
        },
        setAdmin: (state, value: object) => {
            state.commit('setAdmin', value)
        }
    },
    modules: {}
})
