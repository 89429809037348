import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import cookie from '@/utils/cookie'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({showSpinner: false})

const whiteNameList = ['login']
const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/',
        name: 'home',
        redirect: '/dashboard',
        component: () => import('@/views/layout.vue'),
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/views/admin/dashboard.vue'),
                meta: {
                    title: '概览',
                    icon: 'dashboard'
                }
            },
            {
                path: '/crm',
                name: 'crm',
                component: () => import('@/views/admin/crm/index.vue'),
                meta: {
                    title: '客户管理',
                    icon: 'company'
                },
                children: [
                    {
                        path: '/crm/:companyId',
                        name: 'crmDetail',
                        component: () => import('@/views/admin/crm/detail.vue'),
                        meta: {
                            title: '公司详情',
                            menu: false
                        }
                    }
                ]
            },
            {
                path: '/administrator',
                name: 'administrator',
                component: () => import('@/views/admin/administrator.vue'),
                meta: {
                    title: '管理员账号管理',
                    icon: 'administrator'
                }
            },
            {
                path: '/permissions',
                name: 'permissions',
                component: () => import('@/views/admin/permissions.vue'),
                meta: {
                    title: '权限点管理',
                    icon: 'permission'
                }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    NProgress.start()
    const name = <string>to.name
    document.title = <string>to.meta.title
    if (whiteNameList.indexOf(name) > -1) {
        await next()
    } else {
        const token = cookie.get('token')
        if (token) {
            const admin = cookie.get('admin')
            cookie.set('token', token)
            cookie.set('admin', admin)
            await next()
        } else {
            const path = encodeURIComponent(to.fullPath)
            await next({name: 'login', query: {path}, replace: true})
        }
    }
})

router.afterEach(async (to, from) => {
    NProgress.done()
})

export default router
