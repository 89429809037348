import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
// import locale from 'element-plus/lib/locale/lang/zh-cn' // 测试环境打包使用
import locale from 'element-plus/es/locale/lang/zh-cn' // 本地运行，生产环境使用
import SvgIconPlugin from '@/plugins/SvgIconPlugin'
import {createPinia} from 'pinia'

import 'element-plus/dist/index.css'

const pinia = createPinia()
createApp(App)
    .use(store)
    .use(router)
    .use(pinia)
    .use(ElementPlus, {locale})
    .use(SvgIconPlugin, {imports: []})
    .mount('#app')
