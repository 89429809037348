<template>
  <el-watermark :font="data.font" :content="[store.getters.admin.trueName, store.getters.admin.mobile]" :z-index="9999">
    <router-view/>
  </el-watermark>
</template>

<script setup lang="ts">
import {reactive} from 'vue'
import {useStore} from 'vuex'

const store = useStore()

const data = reactive<any>({
  font: {
    color: 'rgba(0, 0, 0, .1)'
  }
})
</script>
<style lang="less">
html, body, ul, li {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%; // 10px
}

li {
  list-style: none;
}

#app {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
}

.a, a {
  cursor: pointer;
  text-decoration: none;
  color: black;

  &:hover {
    color: var(--el-color-primary);
  }
}

.el-dialog {
  border-radius: .8rem !important;

  .el-dialog__body {
    padding: var(--el-dialog-padding-primary) !important;
    max-height: calc(100vh - 20rem);
    overflow-y: auto;
  }
}

.el-drawer__header {
  margin-bottom: 0 !important;

  .el-drawer__title {
    font-size: 1.7rem !important;
    font-weight: 500;
    color: #333333;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.flex-top {
  display: flex;
  align-items: flex-start;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  padding: 1.5rem 0 0 0;

  .el-pagination {
    justify-content: right;
    padding-right: 0;

    .is-last {
      margin-right: 0;
    }
  }
}

.search-bar {
  margin-bottom: 2rem;

  .el-form--inline {
    height: 3.2rem;
  }
}

.container-box {
  background-color: white;
  border-radius: .8rem;
  padding: 1.5rem;
  box-shadow: 0 0 .1rem .1rem rgba(0, 0, 0, .05);
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    margin-block: 0;
    position: relative;
    padding-left: 1.5rem;

    &:before {
      content: '';
      display: block;
      background-color: #0a69c7;
      width: .5rem;
      height: 1.2rem;
      position: absolute;
      border-radius: .2rem;
      top: .55rem;
      left: 0
    }
  }
}

.table {
  border-radius: .8rem;
  box-shadow: 0 0 .1rem .1rem rgba(0, 0, 0, .05);

  &.normal {
    box-shadow: none;
  }

  th {
    background: #f0f0f0 !important;
  }
}
</style>
